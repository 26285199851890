
import React from "react";
import PortableText from '../../components/portableText';
import TestimonialsRepeater from './testimonialsRepeater';
import HeadingTwo from '../../components/Headings/headingTwo';
import SectionMain from '../../components/Section/SectionMain'

const Testimonials = ({ rawIntro }) => {

  let wrapperClassName = 'bg-black py-24';

  return (
    <section className={wrapperClassName}>
      <div className="container mx-auto text-white">
      <HeadingTwo title="Testimonials" white />
        <SectionMain>
        <div className="mb-16">
          <PortableText blocks={rawIntro || []} />
        </div>
        <TestimonialsRepeater />
        </SectionMain>
      </div>
    </section>
  );
}

export default Testimonials;
