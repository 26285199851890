import React from "react";
import PortableText from '../../components/portableText';
import { StyledWrapper } from './newsletterStyles';
import HeadingTwo from '../../components/Headings/headingTwo';
import SectionMain from '../../components/Section/SectionMain'

const NewsLetter = ({ rawIntro }) => {

  return (
    <StyledWrapper className="py-24">
      <div className="container mx-auto selection-black">
        <HeadingTwo title={"NewsLetter"} />
        <SectionMain>
          {/* show if not empty */}
          {rawIntro &&
            <div className="mb-16 text-black">
              <PortableText blocks={rawIntro} />
            </div>
          }
          <form name="newsletter" method="POST" className="newsletter mt-12  mx-auto flex flex-wrap  justify-center" netlify-honeypot="bot-field" data-netlify="true">
            <p className="label">
              <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
              <input type="hidden" name="form-name" value="newsletter" />
            </p>
            <label htmlFor="newsletterEmailAddress" className="label">Email Address: </label>
            <input type="email" id="newsletterEmailAddress" className="w-full sm:w-4/5 p-2" required name="emailAddress" placeholder="Email Address: " />
            <input type="submit" className=" submit w-1/3 mt-6 sm:mt-0 sm:w-1/5 p-2 text-black" value="Submit"  ></input>
          </form>
        </SectionMain>
      </div>

    </StyledWrapper>
  );
}



export default NewsLetter;
