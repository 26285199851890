import React from 'react';

const SectionMain = ({children}) => {
    return (
        <div className="mt-12">
            {children}
        </div>
    );
};

export default SectionMain;