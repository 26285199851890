
import React from 'react';
import { Link } from 'gatsby'
import { StyledTestimonial } from './styledTestimonial';
import { GatsbyImage as Img } from "gatsby-plugin-image/compat";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const TestimonialPanel = ({ data, addLink }) => {

  // required - author / date / testimonial
  // optional - image / pullquote /link

  return (
    <ConditionalWrapper
      condition={addLink}
      wrapper={children => <Link to="/testimonials#main">{children}</Link>}
    >
      <StyledTestimonial >
        <div className="inner p-10 pt-24 bg-gray-900 rounded-md p-8 relative">
          <div className="quote"></div>
          <div className="testimonial mb-8">
            {data.testimonial}
          </div>
          <div className="author">{data.author} -  <time>{data.date}</time> </div>

          {data.link &&
            <a href={data.link} className="linkGold" target="_blank" rel="noreferrer">{data.link}</a>
          }
          {data.image
            ? <Img fixed={data.image.asset.fixed} className="photo rounded-full overflow-hidden pointer-events-none" alt={`Testimonial author photo - ${data.author}`} />
            : <img src="/images/photo-placeholder.png" className="photo rounded-full overflow-hidden pointer-events-none" style={{ width: '150px' }} alt="" />
          }
        </div>
        {data.pullquote &&
          <div className="pullquote mt-8">"{data.pullquote}"</div>
        }
      </StyledTestimonial>
    </ConditionalWrapper>
  );
}



export default TestimonialPanel;
