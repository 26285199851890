import {useStaticQuery, graphql} from 'gatsby'

export const  useTestimonials = () => {
  const {allSanityTestimonial : {nodes: testimonials}} = useStaticQuery(
    graphql`
    query TESTIMONIALS_QUERY {
      allSanityTestimonial {
        nodes {
          _id
          author
          date(formatString: "MMM YYYY")
          link
          pullquote
          testimonial
          image {
            asset {
              fixed(width: 150) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
    `
  );

  return testimonials;
}