import React from "react";
import Banner from './Banner/banner'
import Header from "./Header/header";
import ContactForm from './ContactForm/contact-form';
import Testimonials from "../components/Testimonials/testimonials";
import SocialMedia from "../components/social-media";
import NewsLetter from './Newsletter/newsletter';
import Footer from "./Footer/footer";
import "../styles/layout.css";
import { graphql, StaticQuery } from "gatsby";

const query = graphql`
query LayoutQuery {
  sanityHomePage {
    _rawContactIntro
    _rawNewsletterIntro
    _rawSocialMediaIntro
    _rawTestimonialsIntro
    showTestimonials
  }
  sanityFaqPage {
    showFAQs
  }
  sanitySiteSettings {
    showBlog
    siteSocialMedia {
      facebookUrl
      instagramUrl
      linkedInUrl
      twitterUrl
      youtubeUrl
    }
    logo {
      asset {
        fixed(width: 300){
          ...GatsbySanityImageFixed_noBase64
        }
        fluid(maxWidth: 300){
          ...GatsbySanityImageFluid
        }
      }
    }
  }
}
`;


/**
 * 
 * @param {{bannerTitle: string, showBanner:boolean, bannerImgFluid:object, bannerAlt:string}} props 
 */
const Layout = (props) => {
  const {
    children,
    showBanner,
    bannerImgFluid,
    bannerTitle,
    bannerAlt,
    showTestimonialsSection,

  } = props;


  return (

    <StaticQuery

      query={query}
      render={data => {

        const homePage = data.sanityHomePage;
        const site = data.sanitySiteSettings;
        const { showBlog } = data.sanitySiteSettings;
        const showTestimonialsNav =  data.sanityHomePage.showTestimonials;
        return (
          <React.Fragment>
            <Header
              socialMediaUrls={site.siteSocialMedia}
              logoFixed={data.sanitySiteSettings.logo.asset.fixed}
              showFAQs={data.sanityFaqPage.showFAQs}
              showBlog={showBlog}
              showTestimonialsNav={showTestimonialsNav} />

            {showBanner && <Banner bannerImgFluid={bannerImgFluid} bannerTitle={bannerTitle} bannerAlt={bannerAlt} />}

            <div id="main">
              {children}
            </div>

            <SocialMedia rawIntro={homePage._rawSocialMediaIntro} socialMediaUrls={site.siteSocialMedia} />
            {showTestimonialsSection && <Testimonials rawIntro={homePage._rawTestimonialsIntro} />}
            <NewsLetter rawIntro={homePage._rawNewsletterIntro} />
            <ContactForm rawIntro={homePage._rawContactIntro} />
            <Footer logoFluid={site.logo.asset.fluid} socialMediaUrls={site.siteSocialMedia} showFAQs={data.sanityFaqPage.showFAQs} showTestimonialsNav={showTestimonialsNav}/>

          </React.Fragment>
        )
      }}
    />
  )
}

export default Layout;

