import styled from "styled-components";
import { navBreakpoint } from "../../utils/variables";

export const StyledHeader = styled.header`
  position: relative;
  border-bottom: 5px solid #b4914c;
  padding-top: 66px;

  // workaround for gatsby-plugin-image beta issue
  .logoImg {
    img[data-main-image] {
     
      opacity:1 !important;
    }
    img[data-placeholder-image]{
      display:none;
    }
  }

 

  @media ${navBreakpoint} {
    padding-top: 2.5rem;
    .hidden-lg {
      display: none;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .phoneLink {
    position: absolute;
    color: #fff;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 35px;
    z-index: 1;

    &:hover {
      color: #000;

      &:before {
        background: #b4914c;
      }

      & svg {
        fill: #000 !important;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid #b4914c;
      transform: skew(-10deg) translate(-10px, -2px);
      transition: background 0.5s;
      z-index: -1;
    }

    svg {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      margin-top: 2px;
    }

    &.mobile {
      position: relative;
      justify-content: center;
      padding-top: 0;
      &:before {
        display: none;
      }

      &:hover {
        color: #fff;

        svg {
          fill: #b4914c !important;
        }
      }
    }
  }

  .socialLinks {
    @media ${navBreakpoint} {
      position: absolute;
      top: 10px;
      right: 10px;
      margin-top: 0;

      svg {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }

  .iconLink {
    margin: 5px;
  }
`;
