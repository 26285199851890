import styled from "styled-components";

export const StyledFooter = styled.footer`
  border-top: 3px solid #b4914c;

  a {
    margin-bottom: 10px;
  }

  .heading {
    display: inline-flex;
    position: relative;
    align-self: center;
  }

  .column {
  }

  .footerLogo {
    max-width: 300px;

    & > div:first-child {
      padding-top: 71% !important;
    }
  }

  .socialLink {
    margin: 0 0.25rem;

    svg {
      border: 1.5px solid #b4914c;
      border-radius: 50%;
    }

    &:hover {
      .social-svg-mask path {
        fill: #000;
      }

      .social-svg-icon path {
        fill: #b4914c;
      }
    }

    .social-svg-mask path {
      fill: #b4914c;
      transition: fill 0.5s;
    }

    .social-svg-icon path {
      transition: fill 0.5s;
    }
  }

  .iconLink {
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 10px 0;
    margin-bottom: 1px;

    @media (min-width: 768px) {
      padding: 0;
      margin-bottom: 0;
    }

    &.social {
      justify-content: start;

      svg {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;

        @media (max-width: 767px) {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }

    svg {
      margin-right: 10px;
      margin-top: 0;
    }
  }

  .footerLink {
    display: block;
    padding: 10px 0;
    margin-bottom: 1px;

    @media (min-width: 768px) {
      display: block;
      padding: 0;
      margin-bottom: 0;
    }
  }
`;
