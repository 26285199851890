import { Link } from "gatsby";
import React from "react";
import styles from './header.module.css';
import { useWindowSize } from '../../hooks/hooks';
//import NavList from '../NavList/navList';
//import { GatsbyImage as Img } from "gatsby-plugin-image/compat";
import { PhoneIcon, FacebookIcon, InstagramIcon, TwitterIcon, LinkedInIcon, YouTubeIcon } from '../Icons/Icons';
import { StyledHeader } from './styledHeader';
import { NavbarComponent } from '../NavBar/navbar';
import { navBreakpointUnitless } from '../../utils/variables';
import useSiteMetaData from '../useSiteMetaData';
import { StaticImage } from "gatsby-plugin-image"


const Header = ({ logoFixed, showFAQs, showBlog, socialMediaUrls,showTestimonialsNav }) => {

  const {siteTitle}  = useSiteMetaData();
  const size = useWindowSize();
  const {
    facebookUrl,
    instagramUrl,
    linkedInUrl,
    twitterUrl,
    youtubeUrl } = socialMediaUrls;

  let iconWidth = "50px";

  return (
    <StyledHeader className="pt-10" >



      {size.width > navBreakpointUnitless &&
        <a className="phoneLink text-xl" href="tel:07415901468">
          <PhoneIcon /> <span>07415 901 468</span></a>
      }




      <div className="w-full container mx-auto items-center">

        <Link id="siteTitle" to="/" className={styles.logoLink}>
          {/* <Img fixed={logoFixed} alt={siteTitle}/> */}
          <StaticImage className="logoImg" width={300} height={213}  src="../../images/mzm-events-header-logo.png" alt={siteTitle} />
        </Link>

        <div className="socialLinks mx-auto flex flex-wrap justify-center mt-6">
          <a className="iconLink social" href={facebookUrl || ''} target="_blank" rel="noreferrer" title="Follow us on facebook">
            <FacebookIcon bgColor="#b4914c" width={iconWidth} />
          </a>
          <a className="iconLink social" href={instagramUrl || ''} target="_blank" rel="noreferrer" title="Follow us on instagram">
            <InstagramIcon bgColor="#b4914c" width={iconWidth} />
          </a>
          <a className="iconLink social" href={twitterUrl || ''} target="_blank" rel="noreferrer" title="Follow us on twitter">
            <TwitterIcon bgColor="#b4914c" width={iconWidth} />
          </a>
          <a className="iconLink social" href={linkedInUrl || ''} target="_blank" rel="noreferrer" title="Follow us on linkedin">
            <LinkedInIcon bgColor="#b4914c" width={iconWidth} />
          </a>
          <a className="iconLink social" href={youtubeUrl || ''} target="_blank" rel="noreferrer" title="Follow us on youtube">
            <YouTubeIcon bgColor="#b4914c" width={iconWidth} />
          </a>
        </div>

        {/* <div className="text-white">{size.width}px / {size.height}px</div> */}

        {/* mobile phone link */}
  

          <div className="text-center text-white mt-6 hidden-lg">
            <span className="uppercase">call us today on</span>
            <a className="phoneLink mobile text-4xl mb-6" href="tel:07415901468">
              {/* <PhoneIcon />  */}
              <span>07415 901 468</span></a>
          </div>





        {/* <NavList /> */}
        <NavbarComponent showFAQs={showFAQs} showBlog={showBlog} showTestimonialsNav={showTestimonialsNav} />



      </div>
    </StyledHeader>
  );
};

export default Header;
