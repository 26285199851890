import React, { useState } from 'react';
import { StyledNavBar } from './styledNavBar';
// import { DecorPageNav } from './decorPageNav';
// import { CaterPageNav } from './caterPageNav';
// import { EntertainmentPageNav } from './entertainmentPageNav';
// import { ProductionPageNav } from './productionPageNav';
import { Link } from 'gatsby';
//import { useWindowSize } from '../../hooks/hooks';
//import { navBreakpointUnitless } from '../../utils/variables';


export const NavbarComponent = ({ showFAQs, showBlog, showTestimonialsNav }) => {

  ////////////////////////
  /* DUPLICATED NAV SECTIONS FOR WORKAROUND FOR NOW */
  ///////////////////////

  //const size = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledNavBar >
      <div className="hamburgerBar">
        <button aria-label="Show Mobile Menu" className="icon" onClick={() => setIsOpen(!isOpen)} >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <ul className={`navbarUl  ${isOpen ? 'open' : ''}`} >
        <li className="navbarLi" >
          <Link className="navbarLink" to="/">Home</Link>
        </li>
        {/* <DecorPageNav />
        <CaterPageNav />
        <EntertainmentPageNav />
        <ProductionPageNav /> */}

        <li className="navbarLi" >
          <Link className="navbarLink" to="/decor">Decor</Link>
        </li>

        <li className="navbarLi" >
          <Link className="navbarLink" to="/cater">cater</Link>
        </li>

        <li className="navbarLi" >
          <Link className="navbarLink" to="/entertainment">entertainment</Link>
        </li>

        <li className="navbarLi" >
          <Link className="navbarLink" to="/production">production</Link>
        </li>


        {showBlog &&
          <li className="navbarLi" >
            <Link className="navbarLink" to="/blog">blog</Link>
          </li>
        }



        <li className="navbarLi  hiddenDesktop" >
          <Link className="navbarLink " to="/about">About Us</Link>
        </li>
        <li className="navbarLi hiddenDesktop" >
          <Link className="navbarLink " to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li className="navbarLi hiddenDesktop" >
          <Link className="navbarLink " to="/health-and-safety">Health &amp; Safety</Link>
        </li>
        <li className="navbarLi hiddenDesktop" >
          <Link className="navbarLink " to="/terms-and-conditions">T&amp;Cs</Link>
        </li>

        {showFAQs &&
          <li className="navbarLi hiddenDesktop" >
            <Link className="navbarLink " to="/frequently-asked-questions">FAQs</Link>
          </li>
        }

        {showTestimonialsNav &&
          <li className="navbarLi hiddenDesktop" >
            <Link className="navbarLink " to="/testimonials">Testimonials</Link>
          </li>
        }



        <li className="navbarLi dropdownLi hiddenMobile">
          <a href="/" className="navbarLink dropdownLinkButton" onClick={e => e.preventDefault()}>info</a>
          <ul className="dropdown">
            <li className="navbarLi navbarDropdownLi" >
              <Link className="navbarLink navbarDropdownLink" to="/about">About Us</Link>
            </li>
            <li className="navbarLi navbarDropdownLi" >
              <Link className="navbarLink navbarDropdownLink" to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className="navbarLi navbarDropdownLi" >
              <Link className="navbarLink navbarDropdownLink" to="/health-and-safety">Health &amp; Safety</Link>
            </li>
            <li className="navbarLi navbarDropdownLi" >
              <Link className="navbarLink navbarDropdownLink" to="/terms-and-conditions">T&amp;Cs</Link>
            </li>
            {showFAQs &&
              <li className="navbarLi navbarDropdownLi" >
                <Link className="navbarLink navbarDropdownLink" to="/frequently-asked-questions">FAQs</Link>
              </li>
            }
             {showTestimonialsNav &&
              <li className="navbarLi navbarDropdownLi" >
                <Link className="navbarLink navbarDropdownLink" to="/testimonials">Testimonials</Link>
              </li>
            }
          </ul>
        </li>
      </ul>
    </StyledNavBar>

  );
}



