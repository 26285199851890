
import React from "react";
import styles from './social-media.module.css'
import PortableText from '../components/portableText';
import HeadingTwo from './Headings/headingTwo';
import { FacebookIcon, InstagramIcon, TwitterIcon, LinkedInIcon, YouTubeIcon } from '../components/Icons/Icons';
import SectionMain from '../components/Section/SectionMain';

const iconWidth = "70px";
const iconHeight = "70px";
const linkClass = "mx-2 my-2";

const SocialMedia = ({ rawIntro, socialMediaUrls }) => {
  const {
    facebookUrl,
    instagramUrl,
    linkedInUrl,
    twitterUrl,
    youtubeUrl } = socialMediaUrls;

  let wrapperClassName = styles.wrapper += ' py-24';

  return (
    <section className={wrapperClassName}>
      <div className="container mx-auto">
        <HeadingTwo title={"Social Media"} />
        <SectionMain>
          <PortableText blocks={rawIntro || []} className="mb-16" />
          <div className="mx-auto flex justify-center mt-5 flex-wrap">
            <a className={linkClass} href={facebookUrl || ''} target="_blank" rel="noreferrer" title="Follow us on facebook">
              <FacebookIcon iconColor="#b4914c" width={iconWidth} height={iconHeight}/>
            </a>
            <a className={linkClass} href={instagramUrl || ''} target="_blank" rel="noreferrer" title="Follow us on instagram">
              <InstagramIcon iconColor="#b4914c" width={iconWidth} height={iconHeight} />
            </a>
            <a className={linkClass} href={twitterUrl || ''} target="_blank" rel="noreferrer" title="Follow us on twitter">
              <TwitterIcon iconColor="#b4914c" width={iconWidth} height={iconHeight} />
            </a>
            <a className={linkClass} href={linkedInUrl || ''} target="_blank" rel="noreferrer" title="Follow us on linkedin">
              <LinkedInIcon iconColor="#b4914c" width={iconWidth} height={iconHeight} />
            </a>
            <a className={linkClass} href={youtubeUrl || ''} target="_blank" rel="noreferrer" title="Follow us on youtube">
              <YouTubeIcon iconColor="#b4914c" width={iconWidth} height={iconHeight} />
            </a>
          </div>
        </SectionMain>
      </div>
    </section>
  );
}



export default SocialMedia;
