import React, { useState } from "react";
import styles from './contact-form.module.css';
import MultiSelect from "react-multi-select-component";
import PortableText from '../portableText';
import { StyledContactForm } from './styledContactForm';
import HeadingTwo from '../../components/Headings/headingTwo';
import SectionMain from '../../components/Section/SectionMain';



const ContactForm = ({ rawIntro }) => {

  const serviceOptions = [
    { label: 'Decor', value: 'Decor' },
    { label: 'Cater', value: 'Catering' },
    { label: 'Entertainment', value: 'Entertainment' },
    { label: 'Production', value: 'Production' }
  ]

  const [selected, setSelected] = useState([]);


  //let headingClass = styles.heading += ' my-4 uppercase';
  let inputGroupClass = 'inputGroup w-full sm:w-1/3';
  let inputGroupWideClass = 'inputGroup w-full sm:w-1/2'
  let containerClass = styles.containerPadding += ' container mx-auto';

  const labelClass = styles.label;

  return (
    <StyledContactForm className="py-24">
      <div className={containerClass} id="contactForm">
        <HeadingTwo title="Contact Us" white />

        <SectionMain>
          {/* show if not empty */}
          {rawIntro &&
            <div className="text-white mb-16">
              <PortableText blocks={rawIntro} />
            </div>
          }

          <form name="contact" method="POST" className="mx-auto flex flex-wrap" netlify-honeypot="bot-field" data-netlify="true">
            <p className="hidden">
              <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
              <input type="hidden" name="form-name" value="contact" />
            </p>
            <div className={inputGroupWideClass}>
              <label className={labelClass} htmlFor="contactYourName">Name:</label>
              <input type="text" required id="contactYourName" name="contactYourName" placeholder="Your Name:" />
            </div>
            <div className={inputGroupWideClass}>
              <label className={labelClass} htmlFor="contactEmail">Email:</label>
              <input type="email" required name="email" id="contactEmail" placeholder="Your Email:" />
            </div>
            <div className={inputGroupClass}>
              <label className={labelClass} htmlFor="contactPhone">Phone:</label>
              <input type="phone" required name="pone" id="contactPhone" placeholder="Phone Number:" />
            </div>
            <div className={inputGroupClass}>
              <label className={labelClass} htmlFor="contactEventDate">Preferred Event Date:</label>
              <input id="contactEventDate" type="text" placeholder="Event Date: " onFocus={(e) => e.target.type = 'date'} />
            </div>
            <div className={inputGroupClass}>
            <label className={labelClass} htmlFor="contactBudget" >Your budget:</label>
              <select name="budget" required defaultValue={'DEFAULT'} id="contactBudget">
                <option value="DEFAULT" disabled >Your Budget...</option>
                <option value="Up to £250">Up to £250</option>
                <option value="£250 - £500">£250 - £500</option>
                <option value="£500 - £1000">£500 - £1000</option>
                <option value="£1000 +">£1000 +</option>
              </select>
            </div>
            <div className={inputGroupClass}>
              {/* eslint-disable-next-line */}
            <label className={labelClass} id="servicesSelect">Services Required:</label>
              <div className="multiSelect">
                <MultiSelect
                  options={serviceOptions}
                  value={selected}
                  onChange={setSelected}
                  labelledBy={"servicesSelect"}
                  className={styles.multiSelect}
                  disableSearch={true}
                  hasSelectAll={false}
                  overrideStrings={{ "selectSomeItems": "Select Services", "allItemsAreSelected": "All services selected." }}
                />
              </div>
            </div>
            <div className={inputGroupClass}>
            <label className={labelClass} htmlFor="contactEventType">Event Type:</label>
              <select name="eventType" required defaultValue={'DEFAULT'} id="contactEventType">
                <option value="DEFAULT" disabled >Event Type...</option>
                <option value="Wedding">Wedding</option>
                <option value="Party">Party</option>
                <option value="Corporate">Corporate</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className={inputGroupClass}>
            <label className={labelClass} htmlFor="contactEventDuration">Event Duration:</label>
              <select id="contactEventDuration" name="eventDuration" required defaultValue={'DEFAULT'}>
                <option value="DEFAULT" disabled >Event Duration...</option>
                <option value="0-2hrs">0-2hrs</option>
                <option value="2-6hrs">2-6hrs</option>
                <option value="6-12hrs">6-12hrs</option>
                <option value="12hrs+">12hrs +</option>
              </select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column-reverse', padding: '0 10px', width: '100%' }}>
              <label className={labelClass} htmlFor="contactMessage" style={{ textAlign: 'left', color: '#fff' }}>Message:</label>
              <textarea id="contactMessage" type="message" required name="message" style={{ width: '100%', height: '200px' }} placeholder="Your Message" />
            </div>
            <button type="submit" className="cta submit">Submit</button>
          </form>
        </SectionMain>
      </div>
    </StyledContactForm>
  );
}



export default ContactForm;
