import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetaData = () => {
    const { site } = useStaticQuery(
        graphql`
        query SITE_METADATA_QUERY {
            site {
                siteMetadata {
                    siteTitle
                }
            }
        }
    `
    );

    return site.siteMetadata;
}

export default useSiteMetaData;