
import styled from "styled-components";

export const StyledContactForm = styled.section`

  //some nasty CSS here, I hate forms!

  .dropdown-heading-value {
    padding-left: 5px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select,
  select option {
    color: rgba(0, 0, 0, 0.7);
    background: #fff;
  }

  select:invalid,
  input:invalid,
  select option[value=""] {
    color: rgba(0, 0, 0, 0.7);
  }

  .multiSelect{
      > div{
        background:#fff;
      }

      .dropdown-content{
        background:#fff;
      }
  }

  .multiSelect > div > div,
  select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  .multiSelect > div > div:focus,
  select:focus {
    background-image: linear-gradient(45deg, #b4914c 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #b4914c 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border: none;
    outline: 0;
  }

  .multiSelect {
    height:45px !important;

    & > div{
      height:45px !important;
      border:none !important;

      & > div{
        height:45px !important;
      }
    }

    svg {
      opacity: 0;
    }
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  label {
    display: block;
    margin: 16px 0;
  }

  /*Added for browser compatibility*/
  [hidden] {
    display: none;
  }

  .faded {
    color: rgba(0, 0, 0, 0.7);
  }

  input,
  textarea {
    &::placeholder {
      opacity: 1;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  textarea {
    padding: 10px;
  }

  .inputGroup {
    position: relative;
    margin: 0 0 20px;
    padding:0 10px;
    color: #000;

    /* just for now, remove flex later
    display: flex;
    flex-direction: row; */

    & > label {
      color: #fff;
      text-align: left;
    }

    & > input {
      height: 45px;
      width: 100%;
      padding: 5px 10px;
    }

    select {
      height: 45px;
      padding: 5px 10px;
      width:100%;
    }

    textarea {
      height: 200px;
      width: 100%;
      padding: 10px;
    }
  }

  .submit {
    color: #fff;
    margin-left: 10px;

    &:hover {
      color: #000;
    }
  }

  select,
  input,
  textarea,
  .multiSelect > div > div{
    outline:none !important;
    border: 2px solid transparent !important;
    &:focus-within ,&:focus{
      border: 2px solid #b4914c !important;
    }
  }
`;
