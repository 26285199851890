import React from "react";
import TestimonialPanel from './testimonialPanel';
import { StyledSlider } from './styledSlider';
import Slider from 'react-slick';
import {useTestimonials} from '../../hooks/useTestimonials'

const TestimonialsRepeater = () => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    fade: true,
    autoplaySpeed: 5000,

  };

  const testimonials = useTestimonials();
 
  return (
    <StyledSlider>
      <Slider {...settings}>
        {testimonials.map(t => <TestimonialPanel addLink={true} data={t} key={t._id} />)}
      </Slider>
    </StyledSlider>
  )
}


export default TestimonialsRepeater;
