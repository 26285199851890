import styled from "styled-components";
import { navBreakpoint } from "../../utils/variables";

// this needs redoing with classnames
export const StyledNavBar = styled.nav`
  color: #fff;
  order: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000;
  z-index: 90;

  @media ${navBreakpoint} {
    order: 1;
    margin-bottom: 10px;
    margin-top:10px;
    position: relative;
  }

  .navbarUl {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: left;
    display: none;
    column-count:2;

    &.open {
      display: block;
      border-bottom: 5px solid #b4914c;
      padding-bottom: 30px;
      @media ${navBreakpoint} {
        display: flex;
        border-bottom:none;
        padding-bottom:0;
      }
    }

    @media ${navBreakpoint} {
      display: flex;
      flex-direction: row;
      text-align: center;
      column-count:initial;
    }
  }

  .navbarLi {
    position: relative;
    flex-grow: 1;
    padding: 0 10px;
  }

  .navbarDropdownLi {
  }

  .navbarLink {
    padding: 15px 10px;
    text-transform: capitalize;
    transition: color 0.5s;
    display: inline-block;
    position: relative;

    @media ${navBreakpoint}{
      padding: 20px 10px;
    }

    &:hover {
      color: #b4914c;
      &:before {
        transform-origin: center left;
        transform: scale(1);
        opacity: 1;
      }
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 10px;
      right: 10px;
      //width: 100%;
      height: 2px;
      background: #b4914c;
      bottom: 10px;
      opacity: 0;
      transform: scale(0);
      transform-origin: center right;
      transition: transform 0.6s ease-in-out, opacity 0.6s ease-out;
    }
  }

  .navbarDropdownLink {
    white-space: nowrap;
    padding: 10px 10px;

    &:before {
      display: none;
    }
  }

  .dropdownButton {
  }

  .dropdownLinkButton {
    cursor: pointer;
  }

  .dropdownLi:hover {
    .dropdown {
      display: block;
    }
  }

  .dropdown {
    display: none;

    background-color: #000;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    padding: 10px;

    @media ${navBreakpoint} {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .hamburgerBar {
    padding: 10px;
    text-align: right;

    @media ${navBreakpoint} {
      display: none;
    }
  }

  .icon {
    padding: 16px 12px;
  }

  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #fff;

    & + .icon-bar {
      margin-top: 4px;
    }
  }

  .hiddenMobile {
    display: none;

    @media ${navBreakpoint} {
      display: inline;
    }
  }

  .hiddenDesktop {
    @media ${navBreakpoint} {
      display: none;
    }
  }
`;
