import { Link } from "gatsby";
import React from "react";
import { StyledFooter } from './styledFooter';
import { GatsbyImage as Img } from "gatsby-plugin-image/compat";


import { PhoneIcon, MailIcon, FacebookIcon, InstagramIcon, TwitterIcon, LinkedInIcon, YouTubeIcon } from '../Icons/Icons';

const columnClassName = 'column w-full mb-10 lg:mb-0 lg:w-1/4 lg:flex lg:flex-col';
const headingClassName = 'heading text-xl mt-2';
const listClassName = "pt-2 pb-3 lg:pb-0 lg-pt-6";


const Footer = ({ logoFluid, socialMediaUrls, showFAQs,showTestimonialsNav }) => {
  const {
    facebookUrl,
    instagramUrl,
    linkedInUrl,
    twitterUrl,
    youtubeUrl } = socialMediaUrls;

  let iconWidth = "35px";
  let iconHeight = "35px";

  return (
    <StyledFooter className="py-24">
      <div className="container mx-auto  px-8">
        <div className="text-white flex flex-wrap mt-6">
          <div className={columnClassName}>
            <Img fluid={logoFluid} className="footerLogo mx-auto lg:mx-0" />
          </div>
          <div className={columnClassName}>
            <h3 className={headingClassName}>Services</h3>
            <ul className={listClassName}>
              <li><Link className="footerLink" to="/decor">Decor</Link></li>
              <li><Link className="footerLink" to="/cater">Cater</Link></li>
              <li><Link className="footerLink" to="/entertainment">Entertainment</Link></li>
              <li><Link className="footerLink" to="/production">Production</Link></li>
            </ul>
          </div>
          <div className={columnClassName}>
            <h3 className={headingClassName}>Info</h3>
            <ul className={listClassName}>
              <li><Link className="footerLink" to="/about">About Us</Link></li>
              <li><Link className="footerLink" to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link className="footerLink" to="/health-and-safety">Health &amp; Safety</Link></li>
              <li><Link className="footerLink" to="/terms-and-conditions">Terms and Conditions</Link></li>
              {showFAQs &&
                 <li><Link className="footerLink" to="/frequently-asked-questions">FAQ</Link></li>
              }
               {showTestimonialsNav &&
                 <li><Link className="footerLink" to="/testimonials">Testimonials</Link></li>
              }
            </ul>
          </div>
          <div className={columnClassName}>
            <h3 className={headingClassName}>Contact</h3>
            <ul className={listClassName}>
              <li>
                <a className="iconLink" href="tel:07415901468"><PhoneIcon width="20px" height="20px"/> <span>07415 901 468</span></a>
              </li>
              <li>
                <a className="iconLink" href="mailto:zohaib@mzm.events"><MailIcon width="20px" height="20px" /><span>zohaib@mzm.events</span></a>
              </li>

              <li>
                <ul className="flex flex-wrap justify-center lg:mt-3">
                  <li>
                    <a className="iconLink social" href={facebookUrl || ''} target="_blank" rel="noreferrer" title="Follow us on facebook">
                      <FacebookIcon bgColor="#b4914c" width={iconWidth} height={iconHeight}  />
                    </a>
                  </li>
                  <li>
                    <a className="iconLink social" href={instagramUrl || ''} target="_blank" rel="noreferrer" title="Follow us on instagram">
                      <InstagramIcon bgColor="#b4914c" width={iconWidth} height={iconHeight}  />
                    </a>
                  </li>
                  <li>
                    <a className="iconLink social" href={twitterUrl || ''} target="_blank" rel="noreferrer" title="Follow us on twitter">
                      <TwitterIcon bgColor="#b4914c" width={iconWidth} height={iconHeight}  />
                    </a>
                  </li>
                  <li>
                    <a className="iconLink social" href={linkedInUrl || ''} target="_blank" rel="noreferrer" title="Follow us on linkedin">
                      <LinkedInIcon bgColor="#b4914c" width={iconWidth} height={iconHeight}  />
                    </a>
                  </li>
                  <li>
                    <a className="iconLink social" href={youtubeUrl || ''} target="_blank" rel="noreferrer" title="Follow us on youtube">
                      <YouTubeIcon bgColor="#b4914c" width={iconWidth} height={iconHeight}  />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
};

export default Footer;
