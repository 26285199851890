import React from 'react';

const HeadingTwo = ({title, white, gold}) => {

    let textColor;

    if(white){
        textColor = "#fff";
    }else if(gold){
        textColor = "#b4914c";
    }
    

    return (
        <h2 className="text-4xl sm:text-5xl" style={{color : textColor ? textColor :'inherit' }} >
            {title}
        </h2>
    );
};

export default HeadingTwo;