import styled from "styled-components";
import quote from '../../images/quote.svg';

export const StyledTestimonial = styled.div`
    padding-top:80px;
    max-width:900px;
    margin:0 auto;

    .inner{
        position:relative;
        z-index:1;
    }

    .author{
        font-weight:bold;
    }

    .pullquote{
        font-size: 35px;
        color:#333;
        text-transform:capitalize;
        font-weight:bold;
        font-style:italic;

        @media (min-width:800px){
            font-size: 50px;
        }
      
    }

    .photo{
        position:absolute !important;
        top:0;
        left:50%;
        transform:translate(-50%,-50%);

        img{
          position:absolute !important;
          top:50%;
          pointer-events:none;
        }

        img[data-placeholder-image]{
          top:0;
        }
    }

    .quote{
        width:100px;
        height:100px;
        background-image:url("${quote}");
        background-size:100% 100%;
        position:absolute;
        top:5px;
        right:10px;
       
    }

    .testimonial{
        font-style:italic;
    }

    .linkGold{
        text-decoration:underline;
        text-decoration-thickness: 2px;
        text-decoration-color: #b4914c;
        text-underline-offset: 0.5em;
        position:relative;
        padding-bottom: 0.25em;
        transition:background-color 0.5s;

        &:hover{
            background-color:#b4914c;
        }

    }

`;