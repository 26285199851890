import React from "react";
import styles from './banner.module.css';
import { GatsbyImage as Img } from "gatsby-plugin-image/compat"
import {StyledBanner, StyledBannerWrapper} from './styledBanner';
import { useWindowSize } from '../../hooks/hooks';
import {bannerTitleBreakpoint} from '../../utils/variables';

const headingClassName = 'h1 color font-black  mb-10  text-3xl md:text-3xl lg:text-5xl xl:text-6xl ';

const Banner = ({bannerImgFluid, bannerTitle, bannerAlt }) => {
  const size = useWindowSize();
  let showTitleOnBanner = size.width > bannerTitleBreakpoint;

  // if banner title contains <br/>
  if(bannerTitle.includes('<br/>')){
    const titleBits = /(.*)<br\/>(.*)/.exec(bannerTitle);
    const firstLine = titleBits[1];
    const secondLine = titleBits[2];
    bannerTitle = [`${firstLine}`,<br/>,`${secondLine}`];
  }

  let titleClass = showTitleOnBanner 
    ? headingClassName
    : 'font-black color text-3xl  mt-16 -mb-3 px-5';

    
  return (
    <StyledBannerWrapper>
    <StyledBanner>
      <div className={styles.inner}>
        {bannerImgFluid && 
        // <Img fluid={{...bannerImgFluid, aspectRatio: 10 / 3}} alt={bannerAlt}/>
        <Img fluid={{...bannerImgFluid}} alt={bannerAlt || ''}/>
        }

        
      </div>
    </StyledBanner>

         <h1 className={titleClass} style={ showTitleOnBanner ? {color:'#fff'} : {}}>{bannerTitle}</h1>
    
    </StyledBannerWrapper>
  );

}

export default Banner;
