import styled from "styled-components";

export const StyledWrapper = styled.section`
  //background:#b4914c;
  background: #fff;

  & ::selection {
    background-color: #000;
    color: #b4914d;
  }

  .form {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    border: 1px solid #ccc;
  }

  .label {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  /* .submit{
        border:1px solid #ccc;
    } */
`;
