import styled from "styled-components";

export const StyledBanner = styled.div`
  border-bottom: 5px solid #b4914c;
  position: relative;
`;

export const StyledBannerWrapper = styled.section`
  position: relative;

  .gatsby-image-wrapper img[data-main-image]{
     animation: fadeIn 0.25s both;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  .gatsby-image-wrapper{
    background: #b4914c;
    & > div:first-child{
      padding-top:30% !important;
    }
  }

  .h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    animation: fadeInUp 1.5s 0s ease-out forwards;
    opacity: 0;
    
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.3), 1px 1px 2px rgba(0, 0, 0, 0.75);
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5), -1px -1px 1px rgba(255,255,255,0.5);
    text-shadow:0px 0px 0px var(--color-heading-shadow), -1px -1px 0px var(--color-heading-shadow), 0px -1px 0px var(--color-heading-shadow), 1px -1px 0px var(--color-heading-shadow), 1px 0px 0px var(--color-heading-shadow), 1px 1px 0px var(--color-heading-shadow), 0px 1px 0px var(--color-heading-shadow), -1px 1px 0px var(--color-heading-shadow), -1px 0px 0px var(--color-heading-shadow);


    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.15);
      filter: blur(25px);
    }
  }

  .color{
    color:#b4914c;
  }

  @keyframes fadeInUp {
    0% {
      transform: translate3d(-50%, -10%, 0);
    }

    33% {
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
    }
  }
`;
